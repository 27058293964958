.services {
    padding-bottom: 5rem;
  &__header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 2rem;

    h2 {
      font-size: 3rem;
      max-width: 40%;
      text-transform: capitalize;
      span {
        color: $primary-strong;
      }
    }
    p {
      max-width: 70%;
      padding: 1rem;
    }
  }
  &_list {
    &__item {
      text-decoration: none;
      padding: 1.5rem 1rem;
      border-bottom: 1px solid $gray-dark;
      transition: all 0.1s;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      h3 {
        font-size: 2rem;
        color: $gray-dark;
        transition: all 0.1s;
        width: 50%;
      }
      p {
        max-width: 30%;
          color: $white;
          transition: all 0.2s;
        opacity: 0;
      }
      svg {
        height: 2.5rem;
        width: 2.5rem;
        transition: all 0.2s;
          color: $white;
          opacity: 0;
      }

      &:hover {
        h3 {
          transform: scale(1.1) translateX(5%);
          color: $white;

          &::after {
            content: ".";
            color: $primary-strong;
          }
        }
        p {
          opacity: 1;
        }
        svg {
          opacity: 1;
          transform: translateX(-60px);
        }
      }
    }
  }
}

.use_cases {
  min-height: 80vh;
  background: #000;
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 10%;
  padding-top: 5%;
  h2 {
    color: $light;
    font-size: 3rem;
    margin-bottom: 3rem;
  }
  &__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    .card{
      width: 350px;
      margin: 1rem;
      display: flex;
      flex-direction: column;
      img {
        max-width: 100%;
        border-radius: 1rem 1rem 0 0;
        height: auto;
      }
      &_body {
        p{
          color: $light;
          text-align: center;
          font-weight: 700;
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
        }
        background-color: $dark;
        border-radius: 0 0 1rem 1rem;
      }
    }
  }
}

.base_faq_container_wrapper {
  background: $black;
  color: white;
  padding: 7.5%;
  h2 {
    font-size: 2rem;
    padding-bottom: 3rem;
  }
  .base_faq_container{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-auto-columns: 1fr;
    gap: 1rem;

  .base_faq_wrapper {
    background: $dark;
    border-radius: 48px;
    cursor: pointer;
    max-width: 500px;
    padding: 24px;
    margin: auto;
    margin-bottom: 1rem;
    .question {
      display: flex;
      align-items: center;
      justify-content: space-between;
      
      margin-top: 0.5rem;
      margin-bottom: .5rem;
      p {
        max-width: 90%;
        padding-right: 1rem;
      }
      svg {
        width: 1.75rem;
        height: 1.75rem;
      }
    }
    .answer {
      width: 100%;
      overflow: hidden;
      max-height: 0;
      text-align: center;
      margin-bottom: 0;
      transition: all 0.75s;
      color: #8d8d8d;
      margin-top: 1rem;
    }
  }}
}

.featurette {
  display: flex;
  padding: 5%;
  background-color: $black;
  color: $white;
  //text-align: center;
  align-items: self-start;
  flex-direction:column ;

  h3 {
    font-size: 2rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  p {
    font-size: 1.5rem;
    color: #33607e;   //BIZO!
  }
  
}
.titulo{
  font-size: 2rem !important;
  font-weight: bold;

}
.featurette__container{
  display: flex;
  flex-direction: row;
}
.texto{
  margin-top: 1rem;
  color:antiquewhite !important;
  font-size: 0.9rem !important;
  width: 90%;
  line-height: 1.4rem;
  font-weight: normal ;
}
.column-39 {
  width: 75%;
  justify-content: flex-start;
  display: flex;
  padding-top: 8px;
}
.column-61 {   //esto es lo que esta en web fer bizo
  width: 70%;
  padding-left:8%;
}

@media screen and (max-width: 998px) {
  .base_faq_container_wrapper{
    h2{
      text-align: center;
    }
  .base_faq_container {
    grid-template-columns: 1fr;
    gap: 0;
  }}
}

@media screen and (max-width: 768px) {
  .services {
    &__header {
      flex-wrap: wrap;
      padding-bottom: 1rem;

      h2 {
        font-size: 2rem;
        max-width: 100%;
        text-align: start;
        padding: 1rem;
      }
      p {
        max-width: 100%;
      }
    }
    &_list {
      &__item {
        padding: 1rem;
        h3 {
          font-size: 1.5rem;
          color: $gray-light;
          width: 80%;
        }
        p {
          display: none;
        }
        svg {
          height: 2rem;
          width: 2rem;
          opacity: 1;
        }
      }
    }
  }
  .use_cases {
    padding-left: 5%;
    padding-right: 5%;
    h2 {
      font-size: 2.5rem;
      text-align: center;
    }
  }
  .featurette {
    flex-direction: column;
  }
  .featurette__container{
    flex-direction: column;
  }
  .column-39, .column-61 {
    width: 100%;
  }

}
