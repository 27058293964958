@import "variables";

header {
  padding: 0 10vw;
  color: $black;
  position: fixed;
  top: 0;
  width: 100vw;
  background: rgba($color: $black, $alpha: 0.8);
  height: 10vh;
  z-index: 3;
  a {
    text-decoration: none;
    color: inherit;
  }
}
.menu {
  list-style-type: none;
  margin: 0;
  padding: 0;
  li {
    margin: 1rem;
    padding: 0 1rem;
    font-weight: 600;
    color: $light;
    font-family: $font-secondary;
    cursor: pointer;
    a {
      padding: 1rem 0;
      color: inherit;
      text-decoration: none;
    }
  }
  li:after {
    display: block;
    content: " ";
    border-bottom: solid 2px $primary;
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
  }
  li:hover:after {
    transform: scaleX(1);
  }
  @media screen and (max-width: 768px) {
    left: -2000px;
    height: 90vh;
    top: 100%;
    position: absolute;
    transition: all 0.5s;
    flex-direction: column;
    background-color: #1e252b;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1200 800'%3E%3Cdefs%3E%3CradialGradient id='a' cx='0' cy='800' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23131517'/%3E%3Cstop offset='1' stop-color='%23131517' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='b' cx='1200' cy='800' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23255375'/%3E%3Cstop offset='1' stop-color='%23255375' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='c' cx='600' cy='0' r='600' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23234358'/%3E%3Cstop offset='1' stop-color='%23234358' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='d' cx='600' cy='800' r='600' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%231E252B'/%3E%3Cstop offset='1' stop-color='%231E252B' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='e' cx='0' cy='0' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23050505'/%3E%3Cstop offset='1' stop-color='%23050505' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='f' cx='1200' cy='0' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%230F8ADC'/%3E%3Cstop offset='1' stop-color='%230F8ADC' stop-opacity='0'/%3E%3C/radialGradient%3E%3C/defs%3E%3Crect fill='url(%23a)' width='1200' height='800'/%3E%3Crect fill='url(%23b)' width='1200' height='800'/%3E%3Crect fill='url(%23c)' width='1200' height='800'/%3E%3Crect fill='url(%23d)' width='1200' height='800'/%3E%3Crect fill='url(%23e)' width='1200' height='800'/%3E%3Crect fill='url(%23f)' width='1200' height='800'/%3E%3C/svg%3E");
    background-attachment: fixed;
    background-size: cover;
    background-position-x: 60%;
    width: 100%;
    &.active {
      transform: translateX(2000px);
      li {
        width: 100%;
        text-align: center;
        color: $light;
      }
      li:after {
        display: none;
      }
    }
  }
}

.burger {
  display: none;
  padding: 0;
  @media screen and (max-width: 768px) {
    display: flex;
    border: none;
    background-color: transparent;
    font-size: 2.5rem;
    color: $light;
  }
}
